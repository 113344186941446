import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const NightGodTypeIPage = () => {
  return (
    <Layout title="Night God, Type I">
        <h2>Field Notes</h2>
        <center><Image src="nightgod-typei-1.png" alt="Drawing" /></center>
        <p>I have classified this being as a type of NIGHT GOD.</p>
        <ul>
            <li>She can be invoked with living altars, such as a patch of moonflowers or other night-blooming flowers tended in her honor.</li>
            <li>To her worshipers she is known as a gentle and benign god who eases sleep, protects nocturnal fauna, and lights the way for the dead into the realm of rebirth.</li>
        </ul>
    </Layout>
  )
}

export default NightGodTypeIPage